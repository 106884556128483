import {useQuery} from "@apollo/client"
import {Form, useFormikContext} from "formik"
import * as Yup from "yup"
import {gql} from "~/__generated__"
import {
  TemplateFragmentFragment,
  TemplateInput,
  TemplateStatus,
  TemplateVisibility,
} from "~/__generated__/graphql"
import Button, {ButtonLink} from "~/components/ui/Button"
import FormikField from "~/components/ui/FormikField"
import {newPlaystormPath} from "~/paths"
import {TemplateVisibilityLabels} from "./TemplateVisibilityLabels"
import FieldContainer from "./ui/FieldContainer"
import FieldGroup from "./ui/FieldGroup"

export type TemplateFormValues = TemplateInput

const templateStatusOptions = [
  {
    label: "Draft",
    value: TemplateStatus.Draft,
  },
  {
    label: "Published",
    value: TemplateStatus.Published,
  },
  {
    label: "Archived",
    value: TemplateStatus.Archived,
  },
]

const templateVisibilityOptions = [
  {
    label: TemplateVisibilityLabels.EVERYONE,
    value: TemplateVisibility.Everyone,
  },
  {
    label: TemplateVisibilityLabels.INDIVIDUALS,
    value: TemplateVisibility.Individuals,
  },
  {
    label: TemplateVisibilityLabels.ORGANIZATION_MEMBERS,
    value: TemplateVisibility.OrganizationMembers,
  },
  {
    label: TemplateVisibilityLabels.INDIVIDUALS_AND_ORGANIZATION_MEMBERS,
    value: TemplateVisibility.IndividualsAndOrganizationMembers,
  },
]

export const templateValidationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  duration: Yup.string().required("Estimated duration is required"),
  status: Yup.string().required("Template status is required"),
  visibility: Yup.string()
    .oneOf(Object.values(TemplateVisibility))
    .required("Visibility is required"),
})

const TemplateLink = ({template}: {template: TemplateFragmentFragment}) => {
  return (
    <ButtonLink
      color="outlineDark"
      rounding="full"
      to={newPlaystormPath({
        templateId: template.id,
      })}
      target="_blank"
    >
      {template.status === TemplateStatus.Draft ? "Preview" : "Create"}{" "}
      Playstorm
    </ButtonLink>
  )
}

const organizationsQuery = gql(/* GraphQL */ `
  query Organizations {
    organizations(first: 1000) {
      edges {
        node {
          value: id
          label: name
        }
      }
    }
  }
`)

const TemplateFields = ({template}: {template?: TemplateFragmentFragment}) => {
  const {isSubmitting} = useFormikContext<TemplateFormValues>()
  const formik = useFormikContext<TemplateFormValues>()
  const organizations =
    useQuery(organizationsQuery).data?.organizations.edges.map(e => e.node) ??
    []

  return (
    <Form>
      <FieldContainer>
        <FieldGroup>
          <FormikField
            name="title"
            label="Title"
            autoFocus={true}
            light={true}
          />
          <FormikField
            name="description"
            as="textarea"
            label="Description"
            rows={3}
            light={true}
          />
          <FormikField
            name="heroImage"
            label="Hero Image"
            buttonTitle="Upload Image"
            as="file"
            skipLabelNesting={true}
            uploadedImageUrl={template?.heroImageUrl}
            description="Recommended size 388x286"
          />
          <FormikField
            name="duration"
            label="Estimated Duration"
            light={true}
          />
          <FormikField
            as="select"
            options={templateStatusOptions}
            name="status"
            label="Template Status"
            placeholder=""
          />
          <FormikField
            as="select"
            options={templateVisibilityOptions}
            name="visibility"
            label="Visibility"
            placeholder=""
          />
          {(formik.values.visibility ===
            TemplateVisibility.OrganizationMembers ||
            formik.values.visibility ===
              TemplateVisibility.IndividualsAndOrganizationMembers) && (
            <FormikField
              as="select"
              isMulti={true}
              options={organizations}
              name="organizationIds"
              label="Organizations"
              placeholder=""
            />
          )}
        </FieldGroup>
        <div className="mt-8 flex items-center gap-2">
          <Button type="submit" disabled={isSubmitting} rounding="full">
            Save Template
          </Button>
          {template && template.status !== TemplateStatus.Archived && (
            <TemplateLink template={template} />
          )}
        </div>
      </FieldContainer>
    </Form>
  )
}

export default TemplateFields
