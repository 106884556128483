import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from "@apollo/client"
import {loadDevMessages, loadErrorMessages} from "@apollo/client/dev"
import {relayStylePagination} from "@apollo/client/utilities"
import {createConsumer} from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"

// @ts-expect-error this is a vite-only feature
if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const cable = createConsumer()

const hasSubscriptionOperation = ({query: {definitions}}: any) => {
  return definitions.some(
    ({kind, operation}: any) =>
      kind === "OperationDefinition" && operation === "subscription"
  )
}

const csrfEl = document.querySelector("meta[name=csrf-token]")
const csrfToken = csrfEl ? csrfEl.getAttribute("content") : undefined

if (csrfToken == null) {
  console.warn("missing csrf token")
}

const httpLink = new HttpLink({
  credentials: "same-origin",
  headers: {
    "X-CSRF-Token": csrfToken!,
  },
})

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({cable}),
  httpLink
)

const apolloClient = new ApolloClient({
  uri: "/graphql",
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          templates: relayStylePagination(["visibility", "visibilities"]),
          creativePlaystorms: relayStylePagination(),
          adminPlaystorms: relayStylePagination(),
          answerFavorites: relayStylePagination(),
          users: relayStylePagination(),
          organizations: relayStylePagination(),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

export default apolloClient
