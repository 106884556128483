/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query EditAccountFormQuery {\n    organizations(first: 1000) {\n      edges {\n        node {\n          value: id\n          label: name\n        }\n      }\n    }\n  }\n": types.EditAccountFormQueryDocument,
    "\n  mutation ForgotPassword($input: ForgotPasswordInput!) {\n    forgotPassword(input: $input) {\n      email\n    }\n  }\n": types.ForgotPasswordDocument,
    "\n  fragment OrganizationForm on Organization {\n    id\n    name\n    templates(first: 1000) {\n      ...TemplateTable\n    }\n    members(first: 1000) {\n      ...MemberTable\n    }\n  }\n": types.OrganizationFormFragmentDoc,
    "\n  mutation OrganizationUpdate($input: OrganizationUpdateInput!) {\n    organizationUpdate(input: $input) {\n      organization {\n        id\n        ...OrganizationForm\n      }\n    }\n  }\n": types.OrganizationUpdateDocument,
    "\n  query OrganizationTemplatesQuery {\n    templates(visibility: ORGANIZATION_MEMBERS, first: 1000) {\n      edges {\n        node {\n          id\n          title\n        }\n      }\n    }\n  }\n": types.OrganizationTemplatesQueryDocument,
    "\n  mutation OrganizationAddTemplates($input: AddOrganizationTemplateInput!) {\n    addOrganizationTemplate(input: $input) {\n      templates {\n        id\n      }\n    }\n  }\n": types.OrganizationAddTemplatesDocument,
    "\n  mutation OrganizationCreate($input: OrganizationCreateInput!) {\n    organizationCreate(input: $input) {\n      organization {\n        id\n        ...OrganizationForm\n      }\n    }\n  }\n": types.OrganizationCreateDocument,
    "\n  fragment TemplateTable on TemplateConnection {\n    edges {\n      node {\n        id\n        title\n        status\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n": types.TemplateTableFragmentDoc,
    "\n  mutation RemoveTemplateFromOrganization(\n    $input: RemoveOrganizationTemplateInput!\n  ) {\n    removeOrganizationTemplate(input: $input) {\n      clientMutationId\n    }\n  }\n": types.RemoveTemplateFromOrganizationDocument,
    "\n  fragment MemberTable on UserConnection {\n    edges {\n      node {\n        id\n        name\n        email\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n": types.MemberTableFragmentDoc,
    "\n  mutation RemoveMemberFromOrganization(\n    $input: RemoveOrganizationMemberInput!\n  ) {\n    removeOrganizationMember(input: $input) {\n      clientMutationId\n    }\n  }\n": types.RemoveMemberFromOrganizationDocument,
    "\n  mutation InviteMembers($input: InviteOrganizationMemberInput!) {\n    inviteOrganizationMember(input: $input) {\n      clientMutationId\n      users {\n        id\n      }\n      organizationInvitationCount\n      errors\n    }\n  }\n": types.InviteMembersDocument,
    "\n  query Organizations {\n    organizations(first: 1000) {\n      edges {\n        node {\n          value: id\n          label: name\n        }\n      }\n    }\n  }\n": types.OrganizationsDocument,
    "\n  fragment ViewerFragment on User {\n    id\n    email\n    name\n    firstName\n    lastName\n    organization\n    organizationRole\n    admin\n    completedPlaystormsCount\n    pendingPlaystormsCount\n    hasAnsweredOnboardingSurvey\n    createdAt\n    structuredOrganization {\n      id\n      name\n    }\n  }\n": types.ViewerFragmentFragmentDoc,
    "\n  query Viewer {\n    viewer {\n      ...ViewerFragment\n    }\n  }\n": types.ViewerDocument,
    "\n  fragment AdminAnswerFragment on Answer {\n    id\n    text\n    transcription\n    transcriptionSummary\n    recordingUrl\n    nextQuestionId\n    answerType\n    questionType\n    isFavorite\n    response\n    listItemCount\n    question {\n      ...QuestionFragment\n    }\n    summaryMessage {\n      ...MessageFragment\n    }\n    affirmationMessage {\n      ...MessageFragment\n    }\n    strengtheningMessages {\n      nodes {\n        ...MessageFragment\n      }\n    }\n  }\n": types.AdminAnswerFragmentFragmentDoc,
    "\n  fragment AdminFullPlaystormFragment on Playstorm {\n    id\n    user {\n      id\n      name\n      email\n    }\n    templateId\n    templateTitle\n    title\n    completedAt\n    listItemCount\n    answers {\n      nodes {\n        ...AdminAnswerFragment\n      }\n    }\n    postPlaystormSurveyAnswers {\n      nodes {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n": types.AdminFullPlaystormFragmentFragmentDoc,
    "\n  query AdminPlaystorm($id: ID!) {\n    playstorm(id: $id) {\n      ...AdminFullPlaystormFragment\n    }\n  }\n": types.AdminPlaystormDocument,
    "\n  fragment AdminPlaystormFragment on Playstorm {\n    id\n    user {\n      id\n      name\n    }\n    template {\n      id\n      title\n      status\n    }\n    templateId\n    completedAt\n    title\n  }\n": types.AdminPlaystormFragmentFragmentDoc,
    "\n  query AdminPlaystorms($userId: ID, $after: String) {\n    adminPlaystorms(userId: $userId, after: $after, first: 21) {\n      edges {\n        node {\n          ...AdminPlaystormFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.AdminPlaystormsDocument,
    "\n  fragment AdminUserFragment on User {\n    id\n    email\n    name\n    firstName\n    lastName\n    organization\n    organizationRole\n    structuredOrganization {\n      id\n      name\n    }\n    admin\n    status\n    playstormsCount\n    lastPlaystorm {\n      id\n      createdAt\n    }\n    onboardingSurveyAnswers {\n      nodes {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n": types.AdminUserFragmentFragmentDoc,
    "\n  query AdminUser($id: ID!) {\n    user(id: $id) {\n      ...AdminUserFragment\n    }\n  }\n": types.AdminUserDocument,
    "\n  query AdminUsers($after: String) {\n    users(after: $after, first: 21) {\n      edges {\n        node {\n          ...AdminUserFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.AdminUsersDocument,
    "\n  mutation AnswerCreate($input: AnswerCreateInput!) {\n    answerCreate(input: $input) {\n      answer {\n        ...AnswerFragment\n      }\n    }\n  }\n": types.AnswerCreateDocument,
    "\n  mutation ToggleAnswerFavorite($input: ToggleAnswerFavoriteInput!) {\n    toggleAnswerFavorite(input: $input) {\n      answer {\n        ...PlaystormStepAnswerFragment\n      }\n    }\n  }\n": types.ToggleAnswerFavoriteDocument,
    "\n  fragment AnswerFavoriteFragment on AnswerFavorite {\n    id\n    answerId\n    updatedAt\n    answer {\n      id\n      response\n      playstormId\n      questionType\n      strengtheningMessages {\n        nodes {\n          text\n        }\n      }\n      playstorm {\n        title\n      }\n    }\n  }\n": types.AnswerFavoriteFragmentFragmentDoc,
    "\n  query AnswerFavorites($after: String) {\n    answerFavorites(after: $after, first: 21) {\n      edges {\n        node {\n          ...AnswerFavoriteFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.AnswerFavoritesDocument,
    "\n  fragment AnswerFragment on Answer {\n    id\n    text\n    transcription\n    transcriptionSummary\n    nextQuestionId\n    questionId\n    playstormId\n    answerType\n    questionType\n    isFavorite\n  }\n": types.AnswerFragmentFragmentDoc,
    "\n  query Answer($id: ID!) {\n    answer(id: $id) {\n      ...AnswerFragment\n    }\n  }\n": types.AnswerDocument,
    "\n  mutation AnswerUpdate($input: AnswerUpdateInput!) {\n    answerUpdate(input: $input) {\n      answer {\n        ...AnswerFragment\n      }\n    }\n  }\n": types.AnswerUpdateDocument,
    "\n  fragment CreativePlaystormIndexFragment on Playstorm {\n    id\n    templateTitle\n    createdAt\n    updatedAt\n    completedAt\n    title\n  }\n": types.CreativePlaystormIndexFragmentFragmentDoc,
    "\n  query CreativePlaystorms($after: String) {\n    creativePlaystorms(after: $after, first: 21) {\n      edges {\n        node {\n          ...CreativePlaystormIndexFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.CreativePlaystormsDocument,
    "\n  mutation PresignUpload($input: PresignUploadInput!) {\n    presignUpload(input: $input) {\n      url\n      headers\n      signedId\n    }\n  }\n": types.PresignUploadDocument,
    "\n  mutation MessageCreate($input: MessageCreateInput!) {\n    messageCreate(input: $input) {\n      message {\n        ...MessageFragment\n      }\n    }\n  }\n": types.MessageCreateDocument,
    "\n  fragment MessageTtsJobStatusFragment on Message {\n    ttsJobStatus\n  }\n": types.MessageTtsJobStatusFragmentFragmentDoc,
    "\n  subscription MessageTtsUpdated($messageId: ID!) {\n    messageTtsUpdated(messageId: $messageId) {\n      message {\n        id\n        audioChunk\n        audioChunkIndex\n        ttsJobStatus\n      }\n    }\n  }\n": types.MessageTtsUpdatedDocument,
    "\n  mutation MessageUpdate($input: MessageUpdateInput!) {\n    messageUpdate(input: $input) {\n      message {\n        ...MessageFragment\n      }\n    }\n  }\n": types.MessageUpdateDocument,
    "\n  fragment MessageFragment on Message {\n    id\n    text\n    initialText\n    jobStatus\n    ttsJobStatus\n    answerId\n    messageType\n  }\n": types.MessageFragmentFragmentDoc,
    "\n  fragment MessageUpdatedFragment on Message {\n    text\n    jobStatus\n  }\n": types.MessageUpdatedFragmentFragmentDoc,
    "\n  subscription MessageUpdated($messageId: ID!) {\n    messageUpdated(messageId: $messageId) {\n      message {\n        id\n        jobStatus\n        text\n      }\n    }\n  }\n": types.MessageUpdatedDocument,
    "\n  mutation PlaystormCreate($input: PlaystormCreateInput!) {\n    playstormCreate(input: $input) {\n      playstorm {\n        ...PlaystormFragment\n      }\n      firstQuestionId\n    }\n  }\n": types.PlaystormCreateDocument,
    "\n  fragment PlaystormOverviewFragment on Playstorm {\n    id\n    title\n    templateTitle\n    isOwner\n    completedAt\n    nextQuestionId\n    completedSurveyAt\n    answers {\n      nodes {\n        ...PlaystormOverviewAnswerFragment\n      }\n    }\n  }\n": types.PlaystormOverviewFragmentFragmentDoc,
    "\n  fragment PlaystormOverviewAnswerFragment on Answer {\n    id\n    initialText\n    text\n    transcription\n    summaryMessage {\n      ...MessageFragment\n    }\n    response\n    nextQuestionId\n    playstormId\n    answerType\n    questionType\n    recordingUrl\n    question {\n      ...QuestionFragment\n    }\n    strengtheningMessages {\n      nodes {\n        ...MessageFragment\n      }\n    }\n    isFavorite\n    isOwner\n  }\n": types.PlaystormOverviewAnswerFragmentFragmentDoc,
    "\n  query PlaystormOverview($id: ID!) {\n    playstorm(id: $id) {\n      ...PlaystormOverviewFragment\n    }\n  }\n": types.PlaystormOverviewDocument,
    "\n  fragment PlaystormFragment on Playstorm {\n    id\n    title\n    answerCount\n    isOwner\n    templateId\n  }\n": types.PlaystormFragmentFragmentDoc,
    "\n  query Playstorm($id: ID!) {\n    playstorm(id: $id) {\n      ...PlaystormFragment\n    }\n  }\n": types.PlaystormDocument,
    "\n  fragment PlaystormStepQuestionFragment on Question {\n    id\n    text\n    questionType\n    audioUrl\n    position {\n      index\n      total\n    }\n  }\n": types.PlaystormStepQuestionFragmentFragmentDoc,
    "\n  fragment PlaystormStepAnswerFragment on Answer {\n    id\n    text\n    transcription\n    transcriptionSummary\n    nextQuestionId\n    questionId\n    playstormId\n    answerType\n    questionType\n    recordingUrl\n    question {\n      ...QuestionFragment\n    }\n    affirmationMessage {\n      ...MessageFragment\n    }\n    strengtheningMessages {\n      nodes {\n        ...MessageFragment\n      }\n    }\n    playstorm {\n      ...PlaystormFragment\n    }\n    question {\n      ...PlaystormStepQuestionFragment\n    }\n    isFavorite\n  }\n": types.PlaystormStepAnswerFragmentFragmentDoc,
    "\n  fragment PlaystormStepFragment on PlaystormStep {\n    answer {\n      ...PlaystormStepAnswerFragment\n    }\n  }\n": types.PlaystormStepFragmentFragmentDoc,
    "\n  query PlaystormStep($playstormId: ID!, $questionId: ID!) {\n    playstormStep(playstormId: $playstormId, questionId: $questionId) {\n      ...PlaystormStepFragment\n    }\n  }\n": types.PlaystormStepDocument,
    "\n  mutation QuestionCreate($input: QuestionCreateInput!) {\n    questionCreate(input: $input) {\n      question {\n        ...QuestionFragment\n      }\n    }\n  }\n": types.QuestionCreateDocument,
    "\n  mutation QuestionDelete($input: QuestionDeleteInput!) {\n    questionDelete(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n": types.QuestionDeleteDocument,
    "\n  fragment QuestionFragment on Question {\n    id\n    text\n    affirmationPrompt\n    grandRevealPrompt\n    questionType\n    gptModel\n    audioUrl\n  }\n": types.QuestionFragmentFragmentDoc,
    "\n  query Question($id: ID!) {\n    question(id: $id) {\n      ...QuestionFragment\n    }\n  }\n": types.QuestionDocument,
    "\n  mutation QuestionUpdate($input: QuestionUpdateInput!) {\n    questionUpdate(input: $input) {\n      question {\n        ...QuestionFragment\n      }\n    }\n  }\n": types.QuestionUpdateDocument,
    "\n  query Questions($templateId: ID!) {\n    questions(templateId: $templateId, first: 500) {\n      nodes {\n        ...QuestionFragment\n      }\n    }\n  }\n": types.QuestionsDocument,
    "\n  mutation SurveyAnswerCreate($input: SurveyAnswerCreateInput!) {\n    surveyAnswerCreate(input: $input) {\n      surveyAnswer {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n": types.SurveyAnswerCreateDocument,
    "\n  mutation SurveyAnswerUpdate($input: SurveyAnswerUpdateInput!) {\n    surveyAnswerUpdate(input: $input) {\n      surveyAnswer {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n": types.SurveyAnswerUpdateDocument,
    "\n  fragment SurveyAnswerFragment on SurveyAnswer {\n    id\n    value\n    surveyOptionId\n    surveyQuestionId\n  }\n": types.SurveyAnswerFragmentFragmentDoc,
    "\n  query SurveyAnswers($surveyQuestionId: ID!, $playstormId: ID) {\n    surveyAnswers(surveyQuestionId: $surveyQuestionId, playstormId: $playstormId, first: 500) {\n      nodes {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n": types.SurveyAnswersDocument,
    "\n  fragment SurveyQuestionFragment on SurveyQuestion {\n    id\n    title\n    position\n    nextSurveyQuestionId\n    surveyQuestionType\n    surveyLocation\n    surveyOptions {\n      nodes {\n        id\n        title\n        iconName\n      }\n    }\n  }\n": types.SurveyQuestionFragmentFragmentDoc,
    "\n  query SurveyQuestion($id: ID!) {\n    surveyQuestion(id: $id) {\n      ...SurveyQuestionFragment\n    }\n  }\n": types.SurveyQuestionDocument,
    "\n  query SurveyQuestions($surveyLocation: SurveyLocationEnum, $first: Int = 500) {\n    surveyQuestions(surveyLocation: $surveyLocation, first: $first) {\n      nodes {\n        ...SurveyQuestionFragment\n      }\n    }\n  }\n": types.SurveyQuestionsDocument,
    "\n  mutation TemplateCreate($input: TemplateCreateInput!) {\n    templateCreate(input: $input) {\n      template {\n        ...TemplateFragment\n      }\n    }\n  }\n": types.TemplateCreateDocument,
    "\n  fragment TemplateFragment on Template {\n    id\n    title\n    description\n    duration\n    status\n    heroImageUrl\n    heroImageListItemUrl\n    questionsCount\n    visibility\n    organizationsCount\n    organizations {\n      id\n      name\n    }\n  }\n": types.TemplateFragmentFragmentDoc,
    "\n  query Template($id: ID!) {\n    template(id: $id) {\n      ...TemplateFragment\n    }\n  }\n": types.TemplateDocument,
    "\n  mutation TemplateUpdate($input: TemplateUpdateInput!) {\n    templateUpdate(input: $input) {\n      template {\n        ...TemplateFragment\n      }\n    }\n  }\n": types.TemplateUpdateDocument,
    "\n  query Templates($after: String, $visibilities: [TemplateVisibility!]) {\n    templates(after: $after, first: 21, visibilities: $visibilities) {\n      edges {\n        node {\n          ...TemplateFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.TemplatesDocument,
    "\n  mutation ToggleUserArchived($input: ToggleUserArchivedInput!) {\n    toggleUserArchived(input: $input) {\n      user {\n        ...AdminUserFragment\n      }\n    }\n  }\n": types.ToggleUserArchivedDocument,
    "\n  mutation UserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...ViewerFragment\n      }\n    }\n  }\n": types.UserUpdateDocument,
    "\n  query AdminOrganizationScreenQuery($id: ID!) {\n    organization(id: $id) {\n      id\n      ...OrganizationForm\n    }\n  }\n": types.AdminOrganizationScreenQueryDocument,
    "\n  query AdminOrganizationsScreenQuery($after: String) {\n    organizations(first: 50, after: $after) {\n      edges {\n        node {\n          id\n          name\n          membersCount\n          templatesCount\n          createdAt\n          updatedAt\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.AdminOrganizationsScreenQueryDocument,
    "\n  mutation AdminOrganizationsScreenDeleteOrganizationMutation($id: ID!) {\n    organizationDelete(input: {id: $id}) {\n      clientMutationId\n    }\n  }\n": types.AdminOrganizationsScreenDeleteOrganizationMutationDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EditAccountFormQuery {\n    organizations(first: 1000) {\n      edges {\n        node {\n          value: id\n          label: name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query EditAccountFormQuery {\n    organizations(first: 1000) {\n      edges {\n        node {\n          value: id\n          label: name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ForgotPassword($input: ForgotPasswordInput!) {\n    forgotPassword(input: $input) {\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation ForgotPassword($input: ForgotPasswordInput!) {\n    forgotPassword(input: $input) {\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrganizationForm on Organization {\n    id\n    name\n    templates(first: 1000) {\n      ...TemplateTable\n    }\n    members(first: 1000) {\n      ...MemberTable\n    }\n  }\n"): (typeof documents)["\n  fragment OrganizationForm on Organization {\n    id\n    name\n    templates(first: 1000) {\n      ...TemplateTable\n    }\n    members(first: 1000) {\n      ...MemberTable\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation OrganizationUpdate($input: OrganizationUpdateInput!) {\n    organizationUpdate(input: $input) {\n      organization {\n        id\n        ...OrganizationForm\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OrganizationUpdate($input: OrganizationUpdateInput!) {\n    organizationUpdate(input: $input) {\n      organization {\n        id\n        ...OrganizationForm\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrganizationTemplatesQuery {\n    templates(visibility: ORGANIZATION_MEMBERS, first: 1000) {\n      edges {\n        node {\n          id\n          title\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query OrganizationTemplatesQuery {\n    templates(visibility: ORGANIZATION_MEMBERS, first: 1000) {\n      edges {\n        node {\n          id\n          title\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation OrganizationAddTemplates($input: AddOrganizationTemplateInput!) {\n    addOrganizationTemplate(input: $input) {\n      templates {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OrganizationAddTemplates($input: AddOrganizationTemplateInput!) {\n    addOrganizationTemplate(input: $input) {\n      templates {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation OrganizationCreate($input: OrganizationCreateInput!) {\n    organizationCreate(input: $input) {\n      organization {\n        id\n        ...OrganizationForm\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OrganizationCreate($input: OrganizationCreateInput!) {\n    organizationCreate(input: $input) {\n      organization {\n        id\n        ...OrganizationForm\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TemplateTable on TemplateConnection {\n    edges {\n      node {\n        id\n        title\n        status\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n"): (typeof documents)["\n  fragment TemplateTable on TemplateConnection {\n    edges {\n      node {\n        id\n        title\n        status\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveTemplateFromOrganization(\n    $input: RemoveOrganizationTemplateInput!\n  ) {\n    removeOrganizationTemplate(input: $input) {\n      clientMutationId\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveTemplateFromOrganization(\n    $input: RemoveOrganizationTemplateInput!\n  ) {\n    removeOrganizationTemplate(input: $input) {\n      clientMutationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MemberTable on UserConnection {\n    edges {\n      node {\n        id\n        name\n        email\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n"): (typeof documents)["\n  fragment MemberTable on UserConnection {\n    edges {\n      node {\n        id\n        name\n        email\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveMemberFromOrganization(\n    $input: RemoveOrganizationMemberInput!\n  ) {\n    removeOrganizationMember(input: $input) {\n      clientMutationId\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveMemberFromOrganization(\n    $input: RemoveOrganizationMemberInput!\n  ) {\n    removeOrganizationMember(input: $input) {\n      clientMutationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InviteMembers($input: InviteOrganizationMemberInput!) {\n    inviteOrganizationMember(input: $input) {\n      clientMutationId\n      users {\n        id\n      }\n      organizationInvitationCount\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation InviteMembers($input: InviteOrganizationMemberInput!) {\n    inviteOrganizationMember(input: $input) {\n      clientMutationId\n      users {\n        id\n      }\n      organizationInvitationCount\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Organizations {\n    organizations(first: 1000) {\n      edges {\n        node {\n          value: id\n          label: name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Organizations {\n    organizations(first: 1000) {\n      edges {\n        node {\n          value: id\n          label: name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ViewerFragment on User {\n    id\n    email\n    name\n    firstName\n    lastName\n    organization\n    organizationRole\n    admin\n    completedPlaystormsCount\n    pendingPlaystormsCount\n    hasAnsweredOnboardingSurvey\n    createdAt\n    structuredOrganization {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment ViewerFragment on User {\n    id\n    email\n    name\n    firstName\n    lastName\n    organization\n    organizationRole\n    admin\n    completedPlaystormsCount\n    pendingPlaystormsCount\n    hasAnsweredOnboardingSurvey\n    createdAt\n    structuredOrganization {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Viewer {\n    viewer {\n      ...ViewerFragment\n    }\n  }\n"): (typeof documents)["\n  query Viewer {\n    viewer {\n      ...ViewerFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminAnswerFragment on Answer {\n    id\n    text\n    transcription\n    transcriptionSummary\n    recordingUrl\n    nextQuestionId\n    answerType\n    questionType\n    isFavorite\n    response\n    listItemCount\n    question {\n      ...QuestionFragment\n    }\n    summaryMessage {\n      ...MessageFragment\n    }\n    affirmationMessage {\n      ...MessageFragment\n    }\n    strengtheningMessages {\n      nodes {\n        ...MessageFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment AdminAnswerFragment on Answer {\n    id\n    text\n    transcription\n    transcriptionSummary\n    recordingUrl\n    nextQuestionId\n    answerType\n    questionType\n    isFavorite\n    response\n    listItemCount\n    question {\n      ...QuestionFragment\n    }\n    summaryMessage {\n      ...MessageFragment\n    }\n    affirmationMessage {\n      ...MessageFragment\n    }\n    strengtheningMessages {\n      nodes {\n        ...MessageFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminFullPlaystormFragment on Playstorm {\n    id\n    user {\n      id\n      name\n      email\n    }\n    templateId\n    templateTitle\n    title\n    completedAt\n    listItemCount\n    answers {\n      nodes {\n        ...AdminAnswerFragment\n      }\n    }\n    postPlaystormSurveyAnswers {\n      nodes {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment AdminFullPlaystormFragment on Playstorm {\n    id\n    user {\n      id\n      name\n      email\n    }\n    templateId\n    templateTitle\n    title\n    completedAt\n    listItemCount\n    answers {\n      nodes {\n        ...AdminAnswerFragment\n      }\n    }\n    postPlaystormSurveyAnswers {\n      nodes {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminPlaystorm($id: ID!) {\n    playstorm(id: $id) {\n      ...AdminFullPlaystormFragment\n    }\n  }\n"): (typeof documents)["\n  query AdminPlaystorm($id: ID!) {\n    playstorm(id: $id) {\n      ...AdminFullPlaystormFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminPlaystormFragment on Playstorm {\n    id\n    user {\n      id\n      name\n    }\n    template {\n      id\n      title\n      status\n    }\n    templateId\n    completedAt\n    title\n  }\n"): (typeof documents)["\n  fragment AdminPlaystormFragment on Playstorm {\n    id\n    user {\n      id\n      name\n    }\n    template {\n      id\n      title\n      status\n    }\n    templateId\n    completedAt\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminPlaystorms($userId: ID, $after: String) {\n    adminPlaystorms(userId: $userId, after: $after, first: 21) {\n      edges {\n        node {\n          ...AdminPlaystormFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminPlaystorms($userId: ID, $after: String) {\n    adminPlaystorms(userId: $userId, after: $after, first: 21) {\n      edges {\n        node {\n          ...AdminPlaystormFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminUserFragment on User {\n    id\n    email\n    name\n    firstName\n    lastName\n    organization\n    organizationRole\n    structuredOrganization {\n      id\n      name\n    }\n    admin\n    status\n    playstormsCount\n    lastPlaystorm {\n      id\n      createdAt\n    }\n    onboardingSurveyAnswers {\n      nodes {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment AdminUserFragment on User {\n    id\n    email\n    name\n    firstName\n    lastName\n    organization\n    organizationRole\n    structuredOrganization {\n      id\n      name\n    }\n    admin\n    status\n    playstormsCount\n    lastPlaystorm {\n      id\n      createdAt\n    }\n    onboardingSurveyAnswers {\n      nodes {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUser($id: ID!) {\n    user(id: $id) {\n      ...AdminUserFragment\n    }\n  }\n"): (typeof documents)["\n  query AdminUser($id: ID!) {\n    user(id: $id) {\n      ...AdminUserFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUsers($after: String) {\n    users(after: $after, first: 21) {\n      edges {\n        node {\n          ...AdminUserFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminUsers($after: String) {\n    users(after: $after, first: 21) {\n      edges {\n        node {\n          ...AdminUserFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AnswerCreate($input: AnswerCreateInput!) {\n    answerCreate(input: $input) {\n      answer {\n        ...AnswerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AnswerCreate($input: AnswerCreateInput!) {\n    answerCreate(input: $input) {\n      answer {\n        ...AnswerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ToggleAnswerFavorite($input: ToggleAnswerFavoriteInput!) {\n    toggleAnswerFavorite(input: $input) {\n      answer {\n        ...PlaystormStepAnswerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ToggleAnswerFavorite($input: ToggleAnswerFavoriteInput!) {\n    toggleAnswerFavorite(input: $input) {\n      answer {\n        ...PlaystormStepAnswerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AnswerFavoriteFragment on AnswerFavorite {\n    id\n    answerId\n    updatedAt\n    answer {\n      id\n      response\n      playstormId\n      questionType\n      strengtheningMessages {\n        nodes {\n          text\n        }\n      }\n      playstorm {\n        title\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment AnswerFavoriteFragment on AnswerFavorite {\n    id\n    answerId\n    updatedAt\n    answer {\n      id\n      response\n      playstormId\n      questionType\n      strengtheningMessages {\n        nodes {\n          text\n        }\n      }\n      playstorm {\n        title\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AnswerFavorites($after: String) {\n    answerFavorites(after: $after, first: 21) {\n      edges {\n        node {\n          ...AnswerFavoriteFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query AnswerFavorites($after: String) {\n    answerFavorites(after: $after, first: 21) {\n      edges {\n        node {\n          ...AnswerFavoriteFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AnswerFragment on Answer {\n    id\n    text\n    transcription\n    transcriptionSummary\n    nextQuestionId\n    questionId\n    playstormId\n    answerType\n    questionType\n    isFavorite\n  }\n"): (typeof documents)["\n  fragment AnswerFragment on Answer {\n    id\n    text\n    transcription\n    transcriptionSummary\n    nextQuestionId\n    questionId\n    playstormId\n    answerType\n    questionType\n    isFavorite\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Answer($id: ID!) {\n    answer(id: $id) {\n      ...AnswerFragment\n    }\n  }\n"): (typeof documents)["\n  query Answer($id: ID!) {\n    answer(id: $id) {\n      ...AnswerFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AnswerUpdate($input: AnswerUpdateInput!) {\n    answerUpdate(input: $input) {\n      answer {\n        ...AnswerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AnswerUpdate($input: AnswerUpdateInput!) {\n    answerUpdate(input: $input) {\n      answer {\n        ...AnswerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CreativePlaystormIndexFragment on Playstorm {\n    id\n    templateTitle\n    createdAt\n    updatedAt\n    completedAt\n    title\n  }\n"): (typeof documents)["\n  fragment CreativePlaystormIndexFragment on Playstorm {\n    id\n    templateTitle\n    createdAt\n    updatedAt\n    completedAt\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CreativePlaystorms($after: String) {\n    creativePlaystorms(after: $after, first: 21) {\n      edges {\n        node {\n          ...CreativePlaystormIndexFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query CreativePlaystorms($after: String) {\n    creativePlaystorms(after: $after, first: 21) {\n      edges {\n        node {\n          ...CreativePlaystormIndexFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PresignUpload($input: PresignUploadInput!) {\n    presignUpload(input: $input) {\n      url\n      headers\n      signedId\n    }\n  }\n"): (typeof documents)["\n  mutation PresignUpload($input: PresignUploadInput!) {\n    presignUpload(input: $input) {\n      url\n      headers\n      signedId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation MessageCreate($input: MessageCreateInput!) {\n    messageCreate(input: $input) {\n      message {\n        ...MessageFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation MessageCreate($input: MessageCreateInput!) {\n    messageCreate(input: $input) {\n      message {\n        ...MessageFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MessageTtsJobStatusFragment on Message {\n    ttsJobStatus\n  }\n"): (typeof documents)["\n  fragment MessageTtsJobStatusFragment on Message {\n    ttsJobStatus\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription MessageTtsUpdated($messageId: ID!) {\n    messageTtsUpdated(messageId: $messageId) {\n      message {\n        id\n        audioChunk\n        audioChunkIndex\n        ttsJobStatus\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription MessageTtsUpdated($messageId: ID!) {\n    messageTtsUpdated(messageId: $messageId) {\n      message {\n        id\n        audioChunk\n        audioChunkIndex\n        ttsJobStatus\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation MessageUpdate($input: MessageUpdateInput!) {\n    messageUpdate(input: $input) {\n      message {\n        ...MessageFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation MessageUpdate($input: MessageUpdateInput!) {\n    messageUpdate(input: $input) {\n      message {\n        ...MessageFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MessageFragment on Message {\n    id\n    text\n    initialText\n    jobStatus\n    ttsJobStatus\n    answerId\n    messageType\n  }\n"): (typeof documents)["\n  fragment MessageFragment on Message {\n    id\n    text\n    initialText\n    jobStatus\n    ttsJobStatus\n    answerId\n    messageType\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MessageUpdatedFragment on Message {\n    text\n    jobStatus\n  }\n"): (typeof documents)["\n  fragment MessageUpdatedFragment on Message {\n    text\n    jobStatus\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription MessageUpdated($messageId: ID!) {\n    messageUpdated(messageId: $messageId) {\n      message {\n        id\n        jobStatus\n        text\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription MessageUpdated($messageId: ID!) {\n    messageUpdated(messageId: $messageId) {\n      message {\n        id\n        jobStatus\n        text\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PlaystormCreate($input: PlaystormCreateInput!) {\n    playstormCreate(input: $input) {\n      playstorm {\n        ...PlaystormFragment\n      }\n      firstQuestionId\n    }\n  }\n"): (typeof documents)["\n  mutation PlaystormCreate($input: PlaystormCreateInput!) {\n    playstormCreate(input: $input) {\n      playstorm {\n        ...PlaystormFragment\n      }\n      firstQuestionId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PlaystormOverviewFragment on Playstorm {\n    id\n    title\n    templateTitle\n    isOwner\n    completedAt\n    nextQuestionId\n    completedSurveyAt\n    answers {\n      nodes {\n        ...PlaystormOverviewAnswerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PlaystormOverviewFragment on Playstorm {\n    id\n    title\n    templateTitle\n    isOwner\n    completedAt\n    nextQuestionId\n    completedSurveyAt\n    answers {\n      nodes {\n        ...PlaystormOverviewAnswerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PlaystormOverviewAnswerFragment on Answer {\n    id\n    initialText\n    text\n    transcription\n    summaryMessage {\n      ...MessageFragment\n    }\n    response\n    nextQuestionId\n    playstormId\n    answerType\n    questionType\n    recordingUrl\n    question {\n      ...QuestionFragment\n    }\n    strengtheningMessages {\n      nodes {\n        ...MessageFragment\n      }\n    }\n    isFavorite\n    isOwner\n  }\n"): (typeof documents)["\n  fragment PlaystormOverviewAnswerFragment on Answer {\n    id\n    initialText\n    text\n    transcription\n    summaryMessage {\n      ...MessageFragment\n    }\n    response\n    nextQuestionId\n    playstormId\n    answerType\n    questionType\n    recordingUrl\n    question {\n      ...QuestionFragment\n    }\n    strengtheningMessages {\n      nodes {\n        ...MessageFragment\n      }\n    }\n    isFavorite\n    isOwner\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PlaystormOverview($id: ID!) {\n    playstorm(id: $id) {\n      ...PlaystormOverviewFragment\n    }\n  }\n"): (typeof documents)["\n  query PlaystormOverview($id: ID!) {\n    playstorm(id: $id) {\n      ...PlaystormOverviewFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PlaystormFragment on Playstorm {\n    id\n    title\n    answerCount\n    isOwner\n    templateId\n  }\n"): (typeof documents)["\n  fragment PlaystormFragment on Playstorm {\n    id\n    title\n    answerCount\n    isOwner\n    templateId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Playstorm($id: ID!) {\n    playstorm(id: $id) {\n      ...PlaystormFragment\n    }\n  }\n"): (typeof documents)["\n  query Playstorm($id: ID!) {\n    playstorm(id: $id) {\n      ...PlaystormFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PlaystormStepQuestionFragment on Question {\n    id\n    text\n    questionType\n    audioUrl\n    position {\n      index\n      total\n    }\n  }\n"): (typeof documents)["\n  fragment PlaystormStepQuestionFragment on Question {\n    id\n    text\n    questionType\n    audioUrl\n    position {\n      index\n      total\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PlaystormStepAnswerFragment on Answer {\n    id\n    text\n    transcription\n    transcriptionSummary\n    nextQuestionId\n    questionId\n    playstormId\n    answerType\n    questionType\n    recordingUrl\n    question {\n      ...QuestionFragment\n    }\n    affirmationMessage {\n      ...MessageFragment\n    }\n    strengtheningMessages {\n      nodes {\n        ...MessageFragment\n      }\n    }\n    playstorm {\n      ...PlaystormFragment\n    }\n    question {\n      ...PlaystormStepQuestionFragment\n    }\n    isFavorite\n  }\n"): (typeof documents)["\n  fragment PlaystormStepAnswerFragment on Answer {\n    id\n    text\n    transcription\n    transcriptionSummary\n    nextQuestionId\n    questionId\n    playstormId\n    answerType\n    questionType\n    recordingUrl\n    question {\n      ...QuestionFragment\n    }\n    affirmationMessage {\n      ...MessageFragment\n    }\n    strengtheningMessages {\n      nodes {\n        ...MessageFragment\n      }\n    }\n    playstorm {\n      ...PlaystormFragment\n    }\n    question {\n      ...PlaystormStepQuestionFragment\n    }\n    isFavorite\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PlaystormStepFragment on PlaystormStep {\n    answer {\n      ...PlaystormStepAnswerFragment\n    }\n  }\n"): (typeof documents)["\n  fragment PlaystormStepFragment on PlaystormStep {\n    answer {\n      ...PlaystormStepAnswerFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PlaystormStep($playstormId: ID!, $questionId: ID!) {\n    playstormStep(playstormId: $playstormId, questionId: $questionId) {\n      ...PlaystormStepFragment\n    }\n  }\n"): (typeof documents)["\n  query PlaystormStep($playstormId: ID!, $questionId: ID!) {\n    playstormStep(playstormId: $playstormId, questionId: $questionId) {\n      ...PlaystormStepFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation QuestionCreate($input: QuestionCreateInput!) {\n    questionCreate(input: $input) {\n      question {\n        ...QuestionFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation QuestionCreate($input: QuestionCreateInput!) {\n    questionCreate(input: $input) {\n      question {\n        ...QuestionFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation QuestionDelete($input: QuestionDeleteInput!) {\n    questionDelete(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation QuestionDelete($input: QuestionDeleteInput!) {\n    questionDelete(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment QuestionFragment on Question {\n    id\n    text\n    affirmationPrompt\n    grandRevealPrompt\n    questionType\n    gptModel\n    audioUrl\n  }\n"): (typeof documents)["\n  fragment QuestionFragment on Question {\n    id\n    text\n    affirmationPrompt\n    grandRevealPrompt\n    questionType\n    gptModel\n    audioUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Question($id: ID!) {\n    question(id: $id) {\n      ...QuestionFragment\n    }\n  }\n"): (typeof documents)["\n  query Question($id: ID!) {\n    question(id: $id) {\n      ...QuestionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation QuestionUpdate($input: QuestionUpdateInput!) {\n    questionUpdate(input: $input) {\n      question {\n        ...QuestionFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation QuestionUpdate($input: QuestionUpdateInput!) {\n    questionUpdate(input: $input) {\n      question {\n        ...QuestionFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Questions($templateId: ID!) {\n    questions(templateId: $templateId, first: 500) {\n      nodes {\n        ...QuestionFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query Questions($templateId: ID!) {\n    questions(templateId: $templateId, first: 500) {\n      nodes {\n        ...QuestionFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SurveyAnswerCreate($input: SurveyAnswerCreateInput!) {\n    surveyAnswerCreate(input: $input) {\n      surveyAnswer {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SurveyAnswerCreate($input: SurveyAnswerCreateInput!) {\n    surveyAnswerCreate(input: $input) {\n      surveyAnswer {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SurveyAnswerUpdate($input: SurveyAnswerUpdateInput!) {\n    surveyAnswerUpdate(input: $input) {\n      surveyAnswer {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SurveyAnswerUpdate($input: SurveyAnswerUpdateInput!) {\n    surveyAnswerUpdate(input: $input) {\n      surveyAnswer {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SurveyAnswerFragment on SurveyAnswer {\n    id\n    value\n    surveyOptionId\n    surveyQuestionId\n  }\n"): (typeof documents)["\n  fragment SurveyAnswerFragment on SurveyAnswer {\n    id\n    value\n    surveyOptionId\n    surveyQuestionId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SurveyAnswers($surveyQuestionId: ID!, $playstormId: ID) {\n    surveyAnswers(surveyQuestionId: $surveyQuestionId, playstormId: $playstormId, first: 500) {\n      nodes {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query SurveyAnswers($surveyQuestionId: ID!, $playstormId: ID) {\n    surveyAnswers(surveyQuestionId: $surveyQuestionId, playstormId: $playstormId, first: 500) {\n      nodes {\n        ...SurveyAnswerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SurveyQuestionFragment on SurveyQuestion {\n    id\n    title\n    position\n    nextSurveyQuestionId\n    surveyQuestionType\n    surveyLocation\n    surveyOptions {\n      nodes {\n        id\n        title\n        iconName\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SurveyQuestionFragment on SurveyQuestion {\n    id\n    title\n    position\n    nextSurveyQuestionId\n    surveyQuestionType\n    surveyLocation\n    surveyOptions {\n      nodes {\n        id\n        title\n        iconName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SurveyQuestion($id: ID!) {\n    surveyQuestion(id: $id) {\n      ...SurveyQuestionFragment\n    }\n  }\n"): (typeof documents)["\n  query SurveyQuestion($id: ID!) {\n    surveyQuestion(id: $id) {\n      ...SurveyQuestionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SurveyQuestions($surveyLocation: SurveyLocationEnum, $first: Int = 500) {\n    surveyQuestions(surveyLocation: $surveyLocation, first: $first) {\n      nodes {\n        ...SurveyQuestionFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query SurveyQuestions($surveyLocation: SurveyLocationEnum, $first: Int = 500) {\n    surveyQuestions(surveyLocation: $surveyLocation, first: $first) {\n      nodes {\n        ...SurveyQuestionFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation TemplateCreate($input: TemplateCreateInput!) {\n    templateCreate(input: $input) {\n      template {\n        ...TemplateFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation TemplateCreate($input: TemplateCreateInput!) {\n    templateCreate(input: $input) {\n      template {\n        ...TemplateFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TemplateFragment on Template {\n    id\n    title\n    description\n    duration\n    status\n    heroImageUrl\n    heroImageListItemUrl\n    questionsCount\n    visibility\n    organizationsCount\n    organizations {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment TemplateFragment on Template {\n    id\n    title\n    description\n    duration\n    status\n    heroImageUrl\n    heroImageListItemUrl\n    questionsCount\n    visibility\n    organizationsCount\n    organizations {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Template($id: ID!) {\n    template(id: $id) {\n      ...TemplateFragment\n    }\n  }\n"): (typeof documents)["\n  query Template($id: ID!) {\n    template(id: $id) {\n      ...TemplateFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation TemplateUpdate($input: TemplateUpdateInput!) {\n    templateUpdate(input: $input) {\n      template {\n        ...TemplateFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation TemplateUpdate($input: TemplateUpdateInput!) {\n    templateUpdate(input: $input) {\n      template {\n        ...TemplateFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Templates($after: String, $visibilities: [TemplateVisibility!]) {\n    templates(after: $after, first: 21, visibilities: $visibilities) {\n      edges {\n        node {\n          ...TemplateFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query Templates($after: String, $visibilities: [TemplateVisibility!]) {\n    templates(after: $after, first: 21, visibilities: $visibilities) {\n      edges {\n        node {\n          ...TemplateFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ToggleUserArchived($input: ToggleUserArchivedInput!) {\n    toggleUserArchived(input: $input) {\n      user {\n        ...AdminUserFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ToggleUserArchived($input: ToggleUserArchivedInput!) {\n    toggleUserArchived(input: $input) {\n      user {\n        ...AdminUserFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...ViewerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...ViewerFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminOrganizationScreenQuery($id: ID!) {\n    organization(id: $id) {\n      id\n      ...OrganizationForm\n    }\n  }\n"): (typeof documents)["\n  query AdminOrganizationScreenQuery($id: ID!) {\n    organization(id: $id) {\n      id\n      ...OrganizationForm\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminOrganizationsScreenQuery($after: String) {\n    organizations(first: 50, after: $after) {\n      edges {\n        node {\n          id\n          name\n          membersCount\n          templatesCount\n          createdAt\n          updatedAt\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminOrganizationsScreenQuery($after: String) {\n    organizations(first: 50, after: $after) {\n      edges {\n        node {\n          id\n          name\n          membersCount\n          templatesCount\n          createdAt\n          updatedAt\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminOrganizationsScreenDeleteOrganizationMutation($id: ID!) {\n    organizationDelete(input: {id: $id}) {\n      clientMutationId\n    }\n  }\n"): (typeof documents)["\n  mutation AdminOrganizationsScreenDeleteOrganizationMutation($id: ID!) {\n    organizationDelete(input: {id: $id}) {\n      clientMutationId\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;